import React from 'reactn';
import DavidKirvenView from './DavidKirven-view';
import { useNavigate } from 'react-router';

const DavidKirven = props => {
	const navigate = useNavigate();

	function click() {
		navigate("/newdirector");
	}

	return (
		<DavidKirvenView
			click = {click}
		/>
	)
}

export default DavidKirven;
