import React from 'reactn';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

// components
import BioBox from './BioBox';
import DavidKirven from './DavidKirven';
import DonateButton from './../common/DonateButton';
import EventList from './EventList';
import Footer from '../common/Footer';
import Header from '../common/Header';

// images
import dereckScott from "./images/dereckScott.jpg";
import johnCradler from "./images/johnCradler.jpg";
import lanceVining from "./images/lanceVining.jpg";
import largeGroup from "./images/largeGroup.jpg";
import markHerzing from "./images/markHerzing.jpg";
import ourStory from "./images/ourStory.jpg";
import photoSample1 from "./images/photoSample1.jpg";
import photoSample2 from "./images/photoSample2.jpg";
import photoSample3 from "./images/photoSample3.jpg";
import photoSample4 from "./images/photoSample4.jpg";
import smallGroups from "./images/smallGroups.jpg";
import youthEnsemble from "./images/youthEnsemble.jpg";

// styles
import styles from './Home.module.scss';

export default function HomeView(props) {
	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - Home</title>
					<meta name="description" property='og:description' content='The Cathedral Brass is a symphonic brass and percussion ensemble that performs works from all eras, ranging from baroque to jazz to rock and roll.'/>
					<meta property='og:url' content='https://cathedralbrass.org'/>
					<meta property='og:type' content='website'/>
				</Helmet>
				<Header />
				<div className={styles.introPanel}>
					<div className={styles.introPanelInner}>
						<div className={styles.featureRegion}>
							{/* Insert feature here */}
							<DavidKirven/>
						</div>
						<div className={styles.eventList}>
							<EventList />
							<div className={styles.donateContainer}>
								<DonateButton to="/donate" longForm={true} />
							</div>
						</div>
						<div className="photoAttribution">Photo: Marius Masalar</div>
					</div>
				</div>
				<div className={styles.transformPanel}>
					<div className={styles.transformPanelInner}>
						<div className={styles.transformPanelHead}>
							<div className={styles.transformHeadColumn1}>
								<h2>We Transform*</h2>
							</div>
							<div className={styles.transformHeadColumn2}>
								*not into robots
							</div>
						</div>
						<div className={styles.transformPanelList}>
							<div className={styles.transformListColumn}>
								<h3>Full Brass Choir</h3>
								<img src={largeGroup} alt="The Cathedral Brass onstage at the National Community Band Festival in 2022" />
								<div className={styles.transformListItemCaptionHolder}>
									<span className="photoAttribution">Photo: Art Pittman</span>
								</div>
								<p>Our full group consists of 16 to 18 brass instruments, often joined by a complete orchestral percussion section and a jazz rhythm section. <a href="https://youtube.com/channel/UC_uytSDtzYAurZGmFSD8rUg" target="_blank" rel="noreferrer">Click to hear us</a>.</p>
							</div>
							<div className={styles.transformListColumn}>
								<h3>Small Ensembles</h3>
								<img src={smallGroups} alt="The Cathedral Brass Bones performing Christmas tunes" />
								<div className={styles.transformListItemCaptionHolder}>
									<span className="photoAttribution">Photo: Sarah Quillen</span>
								</div>
								<p>Depending on the needs of the venue and repertoire, we perform in a variety of ensemble configurations, from duet to dectet.</p>
							</div>
							<div className={styles.transformListColumn}>
								<h3>Youth Ensemble</h3>
								<img src={youthEnsemble} alt="Brass instruments on stage" />
								<div className={styles.transformListItemCaptionHolder}>
									<span className="photoAttribution">Photo: Lucas Alexander</span>
								</div>
								<p><Link to="/youth">The Cathedral Brass Youth Ensemble</Link> provides education and performance opportunities for high school-aged brass players.</p>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.ourStoryPanel}>
					<div className={styles.ourStoryPanelInner}>
						<div className={styles.ourStoryPanelText}>
							<h2>Our Story</h2>
							<p>Founded in 1983 by Ray Abell, the Cathedral Brass performs sacred and secular 
								works from all eras in support of the music ministry at Vienna Presbyterian 
								Church. Our members include current and former professionals and members of 
								the Service Bands. The Cathedral Brass is under the direction of Dr. Dereck 
								Scott, Music Director and director of bands at Flint Hill School.</p>
							<p>Our groups are available for concert performances and to support worship 
								services throughout the DC Metropolitan Area. Please contact us for more 
								information.</p>
							<p>We are an independent 501c(3) non-profit organized in the State of Virginia. 
								We benefit from the generosity of donors, as well as the organizations that 
								we serve. If you&rsquo;d like to contribute, <Link to="/donate">donate here</Link>.</p>
						</div>
						<div className={styles.ourStoryPanelPhoto}>
							<img src={ourStory} alt="The Cathedral Brass onstage at the National Community Band Festival in 2022" />
							<div className={styles.ourStoryCaptionHolder}>
								<span className="photoAttribution">Photo: Art Pittman</span>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.photosPanel}>
					<div className={styles.photosPanelInner}>
						<h2>Photos</h2>
						<div className={styles.photosPanelList}>
							<div className={styles.photosPanelItem}>
								<img src={photoSample1} alt="Sample 1" />
								<div className={styles.photosPanelCaptionHolder}>
									<span className="photoAttribution">Photo: Art Pittman</span>
								</div>
							</div>
							<div className={styles.photosPanelItem}>
								<img src={photoSample2} alt="Sample 2" />
								<div className={styles.photosPanelCaptionHolder}>
									<span className="photoAttribution">Photo: Laurie Graham</span>
								</div>
							</div>
							<div className={styles.photosPanelItem}>
								<img src={photoSample3} alt="Sample 3" />
								<div className={styles.photosPanelCaptionHolder}>
									<span className="photoAttribution">Photo: Suzanne Herzing</span>
								</div>
							</div>
							<div className={styles.photosPanelItem}>
								<img src={photoSample4} alt="Sample 4" />
								<div className={styles.photosPanelCaptionHolder}>
									<span className="photoAttribution">Photo: Paul Herzing</span>
								</div>
							</div>
						</div>
						<p><Link to="/photos">View the complete photo gallery »</Link></p>
					</div>
				</div>
				<div className={styles.leadershipPanel}>
					<div className={styles.leadershipPanelInner}>
						<h2>Leadership</h2>
						<div className={styles.leadershipPanelList}>
							<div className={styles.leadershipPanelItem} onClick={(e) => props.openBio('dereckScott')}>
								<div className={styles.leadershipPanelPhotoHolder}>
									<img src={dereckScott} alt="Music Director Dereck Scott" />
									<div className={styles.leadershipPanelPhotoNameplate}>
										<p>Dereck Scott</p>
										<div className={styles.leadershipPanelPhotoTitle}>
											Music Director
										</div>
										<div className={styles.leadershipPanelPhotoIndicator}></div>
									</div>
								</div>
								<div className={styles.leadershipPanelCaptionHolder}>
									<span className="photoAttribution">Photo: Art Pittman</span>
								</div>
							</div>
							<div className={styles.leadershipPanelItem} onClick={(e) => props.openBio('lanceVining')}>
								<div className={styles.leadershipPanelPhotoHolder}>
									<img src={lanceVining} alt="Conductor Emeritus L. Thomas Vining" />
									<div className={styles.leadershipPanelPhotoNameplate}>
										<p>L. Thomas Vining</p>
										<div className={styles.leadershipPanelPhotoTitle}>
											Conductor Emeritus
										</div>
										<div className={styles.leadershipPanelPhotoIndicator}></div>
									</div>
								</div>
								<div className={styles.leadershipPanelCaptionHolder}>
									<span className="photoAttribution">Photo: Laurie Graham</span>
								</div>
							</div>
							<div className={styles.leadershipPanelItem} onClick={(e) => props.openBio('johnCradler')}>
								<div className={styles.leadershipPanelPhotoHolder}>
									<img src={johnCradler} alt="Youth Ensemble Director John Cradler" />
									<div className={styles.leadershipPanelPhotoNameplate}>
										<p>John Cradler</p>
										<div className={styles.leadershipPanelPhotoTitle}>
											Director, Youth Ensemble
										</div>
										<div className={styles.leadershipPanelPhotoIndicator}></div>
									</div>
								</div>
								<div className={styles.leadershipPanelCaptionHolder}>
									<span className="photoAttribution">Photo: John Cradler</span>
								</div>
							</div>
							<div className={styles.leadershipPanelItem} onClick={(e) => props.openBio('markHerzing')}>
								<div className={styles.leadershipPanelPhotoHolder}>
									<img src={markHerzing} alt="Manager Mark Herzing" />
									<div className={styles.leadershipPanelPhotoNameplate}>
										<p>Mark Herzing</p>
										<div className={styles.leadershipPanelPhotoTitle}>
											Manager
										</div>
										<div className={styles.leadershipPanelPhotoIndicator}></div>
									</div>
								</div>
								<div className={styles.leadershipPanelCaptionHolder}>
									<span className="photoAttribution">Photo: BryceTech, LLC</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.recommendationsPanel}>
					<div className={styles.recommendationsPanelInner}>
						<h2>Cathedral Brass Recommends&hellip;</h2>
						<p>The DMV is blessed with great community music organizations. Cathedral Brass members perform in a variety of groups, some of which are represented below. Please support your community musicians!</p>
						<div className={styles.recommendationsPanelList}>
							<div className={styles.recommendationsPanelItem}>
								<h3>Vienna Presbyterian Church Music Ministry</h3>
								Cathedral Brass is proud to be a part of the Music Ministry at Vienna Presbyterian. To learn more about how music enhances the Church’s mission, visit <a href="https://www.viennapres.org/connect/music-ministry/" target="_blank" rel="noreferrer">www.viennapres.org</a>.
							</div>
							<div className={styles.recommendationsPanelItem}>
								<h3>Cherry Blossom Brass</h3>
								Composed of professional musicians, music educators, and community members, the Cherry Blossom Brass Band provides meaningful, challenging artistic experiences for musicians in the Washington D.C. area. <a href="https://www.cbbrassband.com/" target="_blank" rel="noreferrer">www.cbbrassband.com</a>.
							</div>
							<div className={styles.recommendationsPanelItem}>
								<h3>Rockville Brass Band</h3>
								Maryland&rsquo;s Oldest Award-Winning Brass Band, the Rockville Brass Band is dedicated to preserving the tradition of the British brass band. <a href="https://www.rockvillebrassband.org/" target="_blank" rel="noreferrer">www.rockvillebrassband.org</a>.
							</div>
							<div className={styles.recommendationsPanelItem}>
								<h3>City of Fairfax Band Association</h3>
								Under talented and professional leadership, CFBA bands have entertained the Fairfax community, Washington, DC and suburbs, and the greater mid-Atlantic for over 50 years. <a href="https://www.fairfaxband.org/" target="_blank" rel="noreferrer">www.fairfaxband.org</a>.
							</div>
							<div className={styles.recommendationsPanelItem}>
								<h3>McLean Symphony</h3>
								Since 1971 The McLean Symphony has offered local, non-professional instrumentalists an opportunity to perform together, and has given audiences opportunities to enjoy great symphonic music. <a href="https://mclean-symphony.org/" target="_blank" rel="noreferrer">mclean-symphony.org</a>.
							</div>
						</div>
					</div>
				</div>
				<BioBox
					bioData={props.bioData}
					closeBio={props.closeBio}
				/>
			</div>
			<Footer />
		</>
	)
}
