import React from 'reactn';
import DavidKirvenView from './DavidKirven-view';

const DavidKirven = props => {
	return (
		<DavidKirvenView />
	)
}

export default DavidKirven;
