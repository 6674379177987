import React from 'reactn';
import styles from './DavidKirven.module.scss';

// images
import Baton from "./images/baton.jpg";

export default function DavidKirvenView(props) {
	return (
		<div className={styles.feature} onClick={props.click}>
			<h3>David Kirven Named Music Director of The Cathedral Brass</h3>
			<img src={Baton} alt="" />
			<p>To learn more click here.</p>
		</div>
	)
}
