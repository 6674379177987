import React from 'reactn';
import { Helmet } from "react-helmet";

// components
import Footer from '../common/Footer';
import Header from '../common/Header';
import InteriorPage from '../common/InteriorPage';

// styles
import styles from './DavidKirven.module.scss';

// images
import Baton from "./images/baton.jpg";

export default function DavidKirvenView(props) {
	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - David Kirven Named Music Director of The Cathedral Brass</title>
					<meta name="description" property='og:description' content='Learn about our new conductor, David Kirven'/>
					<meta property='og:url' content='https://cathedralbrass.org/newdirector'/>
					<meta property='og:type' content='article'/>
					<meta name="viewport" content="width=device-width, initial-scale=1" />
				</Helmet>
				<Header />
				<InteriorPage>
					<div className={styles.page}>
						<div className={styles.pageMain}>
							<div className={styles.pageTop}>
								<h2>The Cathedral Brass announces&hellip;</h2>
								<h1>David Kirven Named Music Director of The Cathedral Brass</h1>
							</div>
							<div>
								<div className={styles.section}>
									<p class={styles.leadIn}>The Cathedral Brass welcomes David Kirven as our new Music Director, effective January 1st, 2025.</p>
								</div>
								<div className={styles.section}>
									<p>Originally from Paris, Texas, David holds a Bachelor of Music degree in Tuba Performance from Baylor University, and Master of Music Degrees in Instrumental Conducting and Tuba Performance from The University of Kentucky. David is a tuba performer with The U.S. Army Concert Band “Pershing’s Own”. He also serves at the Artistic Director of the Old Bridge Chamber Orchestra and is the Director of Music at Fredericksburg United Methodist Church.</p>
									<p>“We are delighted to have such a distinguished musician and community leader take the baton of the Cathedral Brass,” stated Mark Herzing, Board Chair. “David distinguished himself from a field of outstanding candidates at every stage of our highly competitive evaluation process. We look forward to working with David as he leads the Cathedral Brass to new heights of performance excellence and community impact.”</p>
									<p>The Cathedral Brass recognizes with deepest gratitude the countless contributions of our departing Music Director, Dr. Dereck Scott, who has provided exceptional leadership for the group since assuming the role in 2019.</p>
									<p>Celebrating our 41st year, the Cathedral Brass is dedicated to high quality performance of sacred and secular brass music for the enjoyment of greater Washington DC audiences. Founded in 1983 by Ray and Sharyl Abell, The Cathedral Brass is based at Vienna Presbyterian Church and performs throughout the DC Metro Area with a 22-person large ensemble, a trombone quartet, and a jazz combo. In 2024 the group restarted its Cathedral Brass Youth Ensemble and hosts an annual Day of Brass workshop for young musicians.</p>
								</div>
							</div>
						</div>
						<div className={`${styles.pageSide} noprint`}>
							<img src={Baton} alt="" />
						</div>
					</div>
				</InteriorPage>
			</div>
			<Footer />
		</>
	)
}
